<template>
  <div>
    <PageDynamicBreadcrumb />

    <PageHeader title="Featured Keyword Creator" />

    <q-form @submit="createFeaturedKeyword">
      <q-card flat bordered>
        <q-card-section>
          <h5>Featured Keyword Details</h5>
        </q-card-section>
        <q-card-section>
          <div class="q-gutter-y-md">
            <q-select
              filled
              v-model="businessAccountId"
              :options="businessAccountOptions"
              label="Business Account"
              emit-value
              map-options
              disable="disable"
              hint="Currently restricted to Media.net Ads only."
            ></q-select>
            <q-input
              filled
              label="Keyword"
              v-model="keyword"
              lazy-rules
              :rules="[val => (val && val.length > 1) || 'Enter a Keyword.']"
              :disable="submitting"
              autofocus
            />
            <q-select
              filled
              v-model="productCategoryId"
              :options="productCategoryOptions"
              label="Product Category"
              emit-value
              map-options
            ></q-select>
          </div>
        </q-card-section>
      </q-card>
      <FormActionsSection>
        <template v-slot:primary-actions>
          <q-btn
            label="Create Featured Keyword"
            type="submit"
            color="primary"
            :disable="submitting"
            :loading="submitting"
          />
          <q-btn
            flat
            label="Cancel"
            to="/manage/keywords/featured"
            color="subtle"
            :disable="submitting"
          />
        </template>
      </FormActionsSection>
    </q-form>
  </div>
</template>

<script>
import axios from "axios";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import FormActionsSection from "@/components/UI/FormActionsSection";

export default {
  name: "KeywordExclusionCreator",
  components: {
    PageDynamicBreadcrumb,
    PageHeader,
    FormActionsSection
  },
  data() {
    return {
      keyword: "",
      businessAccountOptions: [],
      businessAccountId: "",
      productCategoryOptions: this.generateSelectOptionsArray(
        [
          { label: "Auto", value: 1 },
          { label: "Jobs", value: 2 },
          { label: "Real Estate", value: 3 },
          { label: "Auto Insurance", value: 4 },
          { label: "Auto Finance", value: 5 },
          { label: "Auto Trade In", value: 6 },
          { label: "Auto Warranty", value: 7 },
          { label: "Auto Repair", value: 8 },
          { label: "Travel", value: 9 }
        ],
        "label",
        "value"
      ),
      productCategoryId: 1,
      submitting: false
    };
  },
  mounted() {
    this.$store.dispatch("businesses/POPULATE_ACCOUNTS_LIST").finally(() => {
      this.businessAccountOptions = this.generateSelectOptionsArray(
        this.$store.state.businesses.accounts.list,
        "name",
        "id"
      );
      this.businessAccountId = "1009";
    });
  },
  methods: {
    createFeaturedKeyword() {
      axios
        .post("https://api-v2.jumpfeed.com/internal/keywords/featured/create", {
          business_account_id: this.businessAccountId,
          keyword: this.keyword,
          product_category_id: this.productCategoryId
        })
        .then(() => {
          this.triggerActionOutcomeAlert("create", true);
          this.$router.push("/manage/keywords/featured");
        })
        .catch(err => {
          console.log(err);
          this.triggerActionOutcomeAlert("create", false);
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
